import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import RefProgImg from "../images/theme-photos-CGpifH-1.png"
import { Link } from "gatsby"

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <Layout>
        <SEO title="Privacy Policy" />
        <div className="about-summary-wrapper">
          <div className="about-summary-content">
            <Container style={{ padding: "100px 20px" }}>
              <h2 className="text-center benefits-flair mb-5 text-white ">
                Privacy Policy and Terms & Conditions for SMS Communications
              </h2>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <h3 className="text-white" style={{ marginBottom: "40px" }}>
                    Privacy Policy
                  </h3>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    1. What Information Do We Collect?
                  </h4>
                  <p className="mb-5 text-white">
                    We collect your phone number when you verbally opt into
                    receiving SMS messages during an interview with our company.
                    We may also collect your phone number via our Contact Us
                    page when you opt into receiving SMS communications. We do
                    not collect other personally identifiable information
                    through SMS opt-ins.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    2. What Do We Use Your Information For?
                  </h4>
                  <p className="mb-3 text-white">
                    Your phone number is used exclusively for the following
                    purposes:
                  </p>
                  <ul className="mb-3 text-white">
                    <li className="text-white">Coordinating interviews</li>
                    <li className=" text-white">
                      Sending updates regarding your candidacy
                    </li>
                    <li className="text-white">
                      Sharing relevant information directly related to job
                      opportunities with our clients
                    </li>
                  </ul>
                  <p className="mb-5 text-white">
                    We will not sell, share, or use your phone number for other
                    purposes, including marketing.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    3. How Do We Protect Your Information?
                  </h4>
                  <p className="mb-5 text-white">
                    We take reasonable and appropriate measures to safeguard
                    your phone number and any related data from unauthorized
                    access, misuse, or disclosure. Our security protocols
                    include technical and administrative safeguards that comply
                    with applicable data protection regulations.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    4. Do We Use Cookies?
                  </h4>
                  <p className="mb-5 text-white">
                    No cookies are used in connection with SMS communications.
                    This SMS Privacy Policy does not include provisions for
                    website privacy practices, as we do not have a separate
                    privacy policy for website or online interactions.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    5. Do We Disclose Any Information to Outside Parties?
                  </h4>
                  <p className="mb-5 text-white">
                    We do not sell, trade, or transfer your phone number to
                    outside parties. This includes third-party marketers and
                    affiliates. Exceptions may include trusted partners or
                    service providers who assist in delivering SMS
                    communications, provided they adhere to strict
                    confidentiality and data protection agreements.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    6. Third-Party Links
                  </h4>
                  <p className="mb-5 text-white">
                    Our SMS communications do not include links to third-party
                    websites or services.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    7. California Online Privacy Protection Act Compliance
                    (CalOPPA)
                  </h4>
                  <p className="mb-5 text-white">
                    We comply with CalOPPA, and users have the right to know how
                    their information is used. This privacy policy provides
                    clear information regarding the collection, use, and
                    protection of your phone number.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    8. Children's Online Privacy Protection Act Compliance
                    (COPPA)
                  </h4>
                  <p className="mb-5 text-white">
                    We do not knowingly collect information from individuals
                    under 13 years of age. SMS communications are limited to
                    adults actively engaging with our company for job-related
                    purposes.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    9. Legal & Licensing
                  </h4>
                  <p className="mb-5 text-white">
                    We adhere to all applicable laws, including TCPA (Telephone
                    Consumer Protection Act) regulations, and ensure that your
                    consent is obtained before sending SMS communications.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    10. Your Consent
                  </h4>
                  <p className="mb-5 text-white">
                    By providing verbal opt-in consent or consent through our
                    Contact Us page, you agree to receive SMS communications
                    from our company. You may withdraw your consent at any time
                    by replying "STOP" to any SMS message or contacting us
                    directly.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    11. Changes to Our Privacy Policy
                  </h4>
                  <p className="mb-5 text-white">
                    This SMS Privacy Policy covers all privacy practices related
                    to SMS communications. We do not maintain separate privacy
                    policies for other forms of communication or online
                    interactions. Any significant changes to this policy will be
                    communicated through updates to our website or via SMS
                    notifications.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    12. CCPA Privacy Notice
                  </h4>
                  <p className="mb-5 text-white">
                    If you are a California resident, you are entitled to
                    additional rights under the California Consumer Privacy Act
                    (CCPA), including the right to request information about the
                    data we collect and to request its deletion. For more
                    information or to make a CCPA request, please contact us.
                  </p>
                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    Contact Us
                  </h4>
                  <p class="text-white" style={{ marginBottom: "60px" }}>
                    For questions or concerns regarding this SMS Privacy Policy,
                    please reach out to us at{" "}
                    <a class="text-white" href="tel:303-867-1111">
                      303-867-1111
                    </a>
                    .
                  </p>

                  <h3 className="text-white" style={{ marginBottom: "40px" }}>
                    Terms & Conditions
                  </h3>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    1. Introduction
                  </h4>
                  <p className="mb-5 text-white">
                    Welcome to PeerSource. By accessing or using our services,
                    including receiving SMS communications, you agree to comply
                    with and be bound by these Terms and Conditions. If you do
                    not agree with these terms, please do not engage with our
                    services.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    2. Consent for SMS Communication
                  </h4>
                  <p className="mb-5 text-white">
                    By providing your consent to receive SMS communications, you
                    acknowledge and agree to receive text messages from
                    PeerSource at the phone number you provide. Information
                    obtained as part of the SMS consent process will not be
                    shared with third parties.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    3. Types of SMS Communications
                  </h4>
                  <p className="mb-3 text-white">
                    If you have consented to receive text messages, you may
                    receive SMS communications related to the following:
                  </p>
                  <ul className="mb-5 text-white">
                    <li className="text-white">
                      Clients: Updates regarding candidates and interview
                      coordination.
                    </li>
                    <li className="text-white">
                      Candidates: Information about your application status,
                      onboarding materials, or other employment-related updates.
                    </li>
                  </ul>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    4. Standard Messaging Disclosures
                  </h4>
                  <p className="mb-5 text-white">
                    Message and data rates may apply. Standard messaging rates
                    will be charged by your mobile service provider. You can
                    opt-out of receiving SMS messages at any time by texting
                    "STOP" to the number from which you received the message.
                    For assistance, text "HELP" or visit our Privacy Policy and
                    Terms & Conditions found {""}
                    <Link to="/privacy" className="text-white">
                      here
                    </Link>
                    .
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    5. Privacy
                  </h4>
                  <p className="mb-5 text-white">
                    We respect your privacy and will only use the information
                    you provide to communicate with you via SMS as outlined
                    above. Your personal data will not be shared with third
                    parties unless required by law or as necessary to deliver
                    the requested service.
                  </p>

                  <h4 className="text-white" style={{ fontSize: "28px" }}>
                    6. Modifications
                  </h4>
                  <p className="mb-5 text-white">
                    We reserve the right to update or modify these Terms and
                    Conditions at any time. Any changes will be posted on this
                    page with an updated revision date. By continuing to engage
                    with our services, you agree to be bound by any changes to
                    these terms.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
